import * as Sentry from '@sentry/react'
import { initializeApp } from 'firebase/app'
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging'
import { createBrowserHistory } from 'history'
import { useEffect, useState } from 'react'
import ReactPiwik from 'react-piwik'
import { useDispatch, useSelector } from 'react-redux'

import { apiAction, getApiDataAction } from '../../redux/actions/api.action'
import {
  getFirebaseConfig,
  getMatomo,
  getSentry,
  setPiwikInstance,
} from '../../redux/actions/app.action'
import {
  addRegisteredDevicesDetail,
  getCurrentlyRegisteredDevicesDetail,
} from '../../redux/description'
import { apiEndPoints, REGISTERED_DEVICES_PATH } from '../../utils/constant'
import { equal } from '../../utils/javascript'
import { loadStateFn, saveStateFn } from '../../utils/localStorage'
import { showToast } from '../../utils/toastService'

export const appContainer = () => {
  const [matomoData, setMatomoData] = useState(null)
  const [messaging, setMessaging] = useState()
  const [registration, setRegistration] = useState()
  const [isFirebaseSupported, setIsFirebaseSupported] = useState(false)

  const dispatch = useDispatch()

  const data = useSelector((state) => state.app?.matomoState)
  const sentryData = useSelector((state) => state.app?.sentryState)
  const { isAuth, firebaseConfig } = useSelector((state) => state.app)
  const registeredDevices = useSelector(
    (state) =>
      state?.api?.[REGISTERED_DEVICES_PATH?.parent]?.[
        REGISTERED_DEVICES_PATH?.child
      ],
  )

  const {
    analytics_enabled: analyticsEnabled,
    analytics_server_site_id: analyticsServerSiteId,
    analytics_server_url: analyticsServerUrl,
  } = data

  const {
    react_app_sentry_dsn: sentryDSN,
    react_app_sentry_traces_sample_rate: sampleRate,
    sentry_environment: sentryEnvironment,
  } = sentryData

  // Function to get device name
  const getDeviceName = (deviceType, deviceId) =>
    `${deviceType === 'WEB' ? 'Desktop' : deviceType}-${deviceId}`

  const getMachineId = () => {
    let machineId = loadStateFn('MachineId')

    if (!machineId) {
      machineId = crypto.randomUUID()
      saveStateFn('MachineId', machineId)
    }

    return machineId
  }

  // Function to determine device type
  const getDeviceType = () => {
    const platform = navigator.userAgentData
    if (platform === 'Android') {
      return 'Android'
    }
    if (platform === 'iOS') {
      return 'iOs'
    }
    return 'WEB'
  }

  const getDeviceFcmToken = async () => {
    if (!registration) return null
    return getToken(messaging, {
      serviceWorkerRegistration: registration,
      vapidKey: firebaseConfig?.firebasePwaVapId,
    })
      .then((currentToken) => {
        if (currentToken) {
          return currentToken
        }
        showToast(
          'Something went wrong while generating the FCM token',
          'error',
        )
        return null
      })
      .catch(() => {
        showToast(
          'Something went wrong while generating the FCM token',
          'error',
        )
        return null
      })
  }

  // Function to get the payload
  const getPayload = async () => {
    const deviceType = getDeviceType()
    const deviceFcmToken = await getDeviceFcmToken()
    const deviceId = getMachineId()
    const deviceName = getDeviceName(deviceType, deviceId)
    const payload = {
      deviceType,
      deviceFcmToken,
      deviceId,
      deviceName,
    }

    return payload
  }

  const linkDevice = async (messaging) => {
    if (messaging) {
      const payload = await getPayload()
      const currentToken = loadStateFn('FCMtoken')
      const isAlreadyTokenRegistered = registeredDevices?.find((el) =>
        equal(el?.deviceFcmToken, currentToken),
      )
      if (
        payload?.deviceFcmToken &&
        (!equal(payload?.deviceFcmToken, currentToken) ||
          !isAlreadyTokenRegistered)
      ) {
        saveStateFn('FCMtoken', payload?.deviceFcmToken)
        const { status } = await dispatch(
          apiAction({
            apiDetails: addRegisteredDevicesDetail(),
            formPath: REGISTERED_DEVICES_PATH,
            payload,
          }),
        )
        if (status) {
          dispatch(
            getApiDataAction({
              apiDetails: getCurrentlyRegisteredDevicesDetail(),
              formPath: REGISTERED_DEVICES_PATH,
            }),
          )
        }
      }
    }
  }

  const isFirebaseServiceSupported = async () => {
    const serviceWorkerSupported = 'serviceWorker' in navigator
    const notificationsSupported = 'Notification' in window
    const pushSupported = 'PushManager' in window
    const messagingSupported = await isSupported()
    const supported =
      serviceWorkerSupported &&
      notificationsSupported &&
      pushSupported &&
      messagingSupported
    setIsFirebaseSupported(supported)
    if (!supported) {
      showToast('Your browser is not supporting notification service.', 'error')
    }
  }

  useEffect(() => {
    dispatch(getMatomo())
    isFirebaseServiceSupported()
    dispatch(
      getApiDataAction({
        apiDetails: getCurrentlyRegisteredDevicesDetail(),
        formPath: REGISTERED_DEVICES_PATH,
        loaderFormPath: { parent: 'notification', child: 'devices' },
      }),
    )
  }, [])

  useEffect(() => {
    if (isAuth) {
      dispatch(getSentry())
      dispatch(getFirebaseConfig())
    }
  }, [isAuth])

  useEffect(() => {
    if (isAuth) {
      setInterval(() => {
        if (loadStateFn('FCMtoken')) dispatch(getFirebaseConfig())
      }, 60000)
    }
  }, [isAuth])

  useEffect(() => {
    if (sentryDSN && sentryEnvironment) {
      // Initialize the sentry environment
      Sentry.init({
        dsn: sentryDSN,
        environment: sentryEnvironment,
        sampleRate,
        tunnel: `../../${apiEndPoints?.sentryTunnel}`,
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      })
    }
  }, [sentryData])

  useEffect(() => {
    if (analyticsEnabled) {
      // eslint-disable-next-line no-new
      const piwik = new ReactPiwik({
        url: analyticsServerUrl,
        siteId: analyticsServerSiteId,
        trackErrors: true,
        enableLinkTracking: true,
        trackDocumentTitle: true,
      })
      setMatomoData({ piwik, ...data })
      dispatch(setPiwikInstance(piwik))
    } else {
      dispatch(setPiwikInstance(null))
    }
  }, [analyticsEnabled])

  useEffect(() => {
    // Register the service worker and pass the config
    if (isFirebaseSupported) {
      if (
        'serviceWorker' in navigator &&
        firebaseConfig?.firebasePwaAppId &&
        isAuth
      ) {
        navigator.serviceWorker
          .register('/service-worker.js')
          .then((registration) => {
            registration.update() // Force update
            setRegistration(registration)
            if (registration.active) {
              registration.active.postMessage({
                type: 'SET_FIREBASE_CONFIG',
                firebaseConfig,
              })
            }

            navigator?.serviceWorker?.addEventListener(
              'controllerchange',
              () => {
                if (navigator.serviceWorker.controller) {
                  navigator.serviceWorker.controller.postMessage({
                    type: 'SET_FIREBASE_CONFIG',
                    firebaseConfig,
                  })
                }
              },
            )
          })
      }

      if (firebaseConfig?.firebasePwaApiKey) {
        const firebaseApp = initializeApp({
          apiKey: firebaseConfig?.firebasePwaApiKey,
          authDomain: firebaseConfig?.firebasePwaAuthDomain,
          projectId: firebaseConfig?.firebasePwaProjectId,
          storageBucket: firebaseConfig?.firebasePwaStorageBucket,
          messagingSenderId: firebaseConfig?.firebasePwaMessagingSenderId,
          appId: firebaseConfig?.firebasePwaAppId,
        })
        const messaging = getMessaging(firebaseApp)
        setMessaging(messaging)
        linkDevice(messaging)
        // Handle foreground messages
        onMessage(messaging, (payload) => {
          // Customize notification here
          const notificationTitle = payload?.notification?.title
          const notificationOptions = {
            body: payload?.notification?.body,
            icon: '/firebase-logo.png',
          }

          // eslint-disable-next-line no-new
          new Notification(notificationTitle, notificationOptions)
        })
      }
    }
  }, [firebaseConfig, isAuth, isFirebaseSupported])

  useEffect(() => {
    if (registration && messaging && isFirebaseSupported) {
      linkDevice(messaging)
    }
  }, [registration, messaging, isFirebaseSupported])

  const trackAtConnect = false
  const history = createBrowserHistory()

  return { matomoData, history, trackAtConnect }
}
