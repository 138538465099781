import TooltipTable from '../../presentation/workOpportunities/TooltipTable'
import { cardDetail, getBeforeBookings } from '../../redux/description'
import {
  woByJobDefaultFilterOptions,
  woByJobGetFilterOptions,
} from '../../shared/description/tableWithCard.descripotion'
import {
  addLabelNote,
  apiEndPoints,
  assignedEmployee,
  defaultTrueValidation,
  doveGray,
  error,
  flamenco,
  fullWidth,
  jordyBlue,
  noDetailChangesFound,
  noPlanningFound,
  notEmptyOrNull,
  planningCounts,
  plannings,
  purple,
  removeLabelNote,
  suggestedEmployee,
  topLocation,
  tradewind,
  workOpportunities,
  workopportunity,
} from '../../utils/constant'
import {
  detailedChangePath,
  launchpadFormPath,
  launchpadLabels,
  launchpadWorkOpportunitiesFormPath,
  missionDetails,
  missionFormPath,
  missionWOPath,
  planningsDetails,
} from '../formPath.description'
import { actions } from '../table/action.description'
import { options } from '../table/options.description'
import { graphOptions } from './talent.description'

export const regularAssignedColor = jordyBlue
export const regularChangedColor = tradewind
export const genericAssignedColor = flamenco
export const genericGenericColor = doveGray
export const regularGenericColor = error
export const manuallyUpdated = purple

const suggestedFormPath = { parent: missionFormPath, child: suggestedEmployee }
const assignedFormPath = { parent: missionFormPath, child: assignedEmployee }

const commonColumn = {
  grade: {
    name: 'grade',
    label: 'Grade',
    filterName: 'Grade is',
    ...options({
      filterType: 'multiselect',
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      isConvert: false,
    }),
  },
}

const infoBooking = [
  {
    type: `C`,
    description: `Those will be optimized.`,
  },
  {
    type: `N & T`,
    description: `Considered during optimization (for example for total
  hours), but not changed.`,
  },
  {
    type: `V`,
    description: `Considered as absences.`,
  },
  {
    type: 'Other',
    description: `Ignored for optimization, so they will not be changed but
 will also not be considered for hourly limits.`,
  },
]

export const suggestedEmployeeColumn = [
  {
    name: 'originalId',
    label: 'Original Id',
    ...options({
      filter: false,
      customRender: true,
      isConvert: false,
      display: false,
      isOriginalId: true,
    }),
  },
  {
    name: 'name',
    label: 'Name',
    ...options({
      filter: false,
      customRender: true,
      isConvert: false,
    }),
  },
  { ...commonColumn?.grade },
  {
    name: 'fit',
    label: 'Fit Score',
    filterName: 'Fit Score ',
    ...options({
      customRender: true,
      isShowColorBox: true,
      hasInfo: true,
      hideColumnHeader: true,
      tooltipTitle: "Only considers Booking Type 'C'",
      formPath: { ...suggestedFormPath, childObj: 'fitScore' },
    }),
  },
  {
    name: 'time',
    label: 'Time Score',
    filterName: 'Time Score ',
    ...options({
      customRender: true,
      isShowColorBox: true,
      formPath: { ...suggestedFormPath, childObj: 'timeScore' },
    }),
  },
  {
    name: 'hours',
    label: 'Hours',
    filterName: 'Hours ',
    ...options({
      hasFilterOptions: true,
      customRender: true,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'timeFte',
    label: 'FTE (%)',
    filterName: 'FTE (%) ',
    ...options({
      hasFilterOptions: true,
      customRender: true,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'entryDate',
    label: 'Entry Date',
    filterName: 'Entry',
    ...options({
      hasFilterOptions: true,
      display: false,
      customRender: true,
    }),
  },
  {
    name: 'leaveDate',
    label: 'Leave Date',
    filterName: 'Leave',
    ...options({
      hasFilterOptions: true,
      display: false,
      customRender: true,
    }),
  },
  {
    name: 'newJoiner',
    label: 'New Joiner ',
    filterName: 'New joiner =',
    ...options({
      hasFilterOptions: true,
      display: false,
      customRender: true,
    }),
  },
  {
    name: 'labels',
    label: 'Labels',
    filterName: 'Label is',
    ...options({
      sort: false,
      filter: false,
      customRenderValue: true,
      customRender: true,
      isTag: true,
      display: false,
    }),
  },
  {
    name: 'modifiedLabel',
    label: 'Labels',
    filterName: 'Label is',
    hideDrag: true,
    ...options({
      display: false,
      viewColumns: false,
      sort: false,
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      isTag: true,
    }),
  },
]

export const assignedEmployeeColumn = [
  {
    name: 'originalId',
    label: 'Original Id',
    ...options({
      filter: false,
      customRender: true,
      isConvert: false,
      display: false,
      isOriginalId: true,
    }),
  },
  {
    name: 'name',
    label: 'Name',
    ...options({
      filter: false,
      customRender: true,
      isConvert: false,
    }),
  },
  { ...commonColumn?.grade },
  {
    name: 'fit',
    label: 'Fit Score',
    filterName: 'Fit Score ',
    ...options({
      customRender: true,
      isShowColorBox: true,
      hasInfo: true,
      hideColumnHeader: true,
      tooltipTitle: "Only considers Booking Type 'C'",
      formPath: { ...assignedFormPath, childObj: 'fitScore' },
    }),
  },
  {
    name: 'time',
    label: 'Time Score',
    filterName: 'Time Score ',
    ...options({
      customRender: true,
      isShowColorBox: true,
      formPath: { ...assignedFormPath, childObj: 'timeScore' },
    }),
  },
  {
    name: 'hours',
    label: 'Hours',
    filterName: 'Hours ',
    ...options({
      hasFilterOptions: true,
      customRender: true,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'timeFte',
    label: 'FTE (%)',
    filterName: 'FTE (%) ',
    ...options({
      hasFilterOptions: true,
      customRender: true,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'entryDate',
    label: 'Entry Date',
    filterName: 'Entry',
    ...options({
      hasFilterOptions: true,
      display: false,
      customRender: true,
    }),
  },
  {
    name: 'leaveDate',
    label: 'Leave Date',
    filterName: 'Leave',
    ...options({
      hasFilterOptions: true,
      display: false,
      customRender: true,
    }),
  },
  {
    name: 'newJoiner',
    label: 'New Joiner ',
    filterName: 'New joiner =',
    ...options({
      hasFilterOptions: true,
      display: false,
      customRender: true,
    }),
  },
  {
    name: 'labels',
    label: 'Labels',
    filterName: 'Label is',
    ...options({
      sort: false,
      filter: false,
      customRenderValue: true,
      customRender: true,
      isTag: true,
      display: false,
    }),
  },
  {
    name: 'modifiedLabel',
    label: 'Labels',
    filterName: 'Label is',
    hideDrag: true,
    ...options({
      display: false,
      viewColumns: false,
      sort: false,
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      isTag: true,
    }),
  },
]

export const bookingColumns = [
  {
    name: 'id',
    label: 'ID',
    ...options({
      filter: false,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'originalId',
    label: 'Original ID',
    ...options({
      filter: false,
      customRender: true,
      isConvert: false,
      isOriginalId: true,
      display: false,
    }),
  },
  {
    name: 'bookingGrade',
    label: 'Requested Grade',
    filterName: 'Requested Grade is',
    type: 'multiselect',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      isConvert: false,
    }),
  },
  {
    name: 'operatingUnit',
    label: 'Operating Unit',
    filterName: 'Operating Unit is',
    type: 'multiselect',
    ...options({
      filterType: 'multiselect',
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
    }),
  },
  {
    name: 'bookingType',
    label: 'Booking Type',
    filterName: 'Booking Type is',
    type: 'multiselect',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      hasInfo: true,
      tooltipTitle: <TooltipTable data={infoBooking} />,
      hideColumnHeader: true,
      display: false,
    }),
  },
  {
    name: 'bookingPriority',
    label: 'Priority',
    filterName: 'Priority is',
    type: 'range',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'jobId',
    label: 'Job ID',
    filterName: 'Job ID is',
    type: 'multiselect',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'engagement',
    label: 'Job Name',
    filterName: 'Job Name is',
    type: 'multiselect',
    ...options({
      isConvert: false,
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'jobCity',
    label: 'Job City',
    filterName: 'Job City is',
    type: 'multiselect',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      hideColumnHeader: true,
      hasInfo: true,
      tooltipTitle: 'Work Opportunity city',
    }),
  },
  {
    name: 'jobPostalCode',
    label: 'Job Postal Code',
    filterName: 'Job Postal Code is',
    type: 'multiselect',
    ...options({
      filter: true,
      customRenderValue: true,
      customRender: true,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'jobExpectedEarnings',
    label: 'Job Expected Earnings',
    filterName: 'Job Expected Earnings is',
    type: 'range',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      filterType: 'multiselect',
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'jobExpectedExpenses',
    label: 'Job Expected Expenses',
    filterName: 'Job Expected Expenses is',
    type: 'range',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      filterType: 'multiselect',
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'jobFee',
    label: 'Job Fee',
    filterName: 'Job Fee is',
    type: 'range',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      filterType: 'multiselect',
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'jobManagerId',
    label: 'Job Manager ID',
    filterName: 'Job Manager ID is',
    type: 'multiselect',
    ...options({
      customRender: true,
      hasFilterOptions: false,
      customRenderValue: true,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'jobManager',
    label: 'Job Manager',
    filterName: 'Job Manager is',
    type: 'multiselect',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      display: false,
    }),
  },
  {
    name: 'jobPartner',
    label: 'Job Partner/Director',
    filterName: 'Job Partner/Director is',
    type: 'multiselect',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      filterType: 'multiselect',
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'jobPartnerId',
    label: 'Job Partner/Director ID',
    filterName: 'Job Partner/Director ID is',
    type: 'multiselect',
    ...options({
      hasFilterOptions: false,
      customRenderValue: true,
      customRender: true,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'sdcRate',
    label: 'SDC Rate',
    filterName: 'SDC Rate is',
    type: 'range',
    ...options({
      display: false,
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
    }),
  },
  {
    name: 'hours',
    label: 'Hours',
    filterName: 'Hours ',
    type: 'range',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      isConvert: false,
    }),
  },
  {
    name: 'bookingStartDate',
    type: 'dateRange',
    label: 'Start Date',
    filterName: 'Start Date',
    ...options({
      filter: true,
      customRenderValue: true,
      customRender: true,
      hasFilterOptions: true,
    }),
  },
  {
    name: 'bookingEndDate',
    type: 'dateRange',
    label: 'End Date',
    filterName: 'End Date',
    ...options({
      filter: true,
      customRenderValue: true,
      customRender: true,
      hasFilterOptions: true,
    }),
  },
  {
    name: 'bookingStartWeek',
    label: 'Start Week',
    filterName: 'Start Week is',
    ...options({
      filter: false,
      filterType: 'multiselect',
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'bookingEndWeek',
    label: 'End Week',
    filterName: 'End Week is',
    ...options({
      filter: false,
      filterType: 'multiselect',
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'clientId',
    label: 'Client ID',
    filterName: 'Client ID is',
    type: 'multiselect',
    ...options({
      isConvert: false,
      customRender: true,
      hasFilterOptions: false,
      customRenderValue: true,
      display: false,
    }),
  },
  {
    name: 'clientName',
    label: 'Client Name',
    type: 'multiselect',
    filterName: 'Client Name is',
    ...options({
      isConvert: false,
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      filterType: 'multiselect',
    }),
  },
  {
    name: 'bookingLabels',
    label: 'Work Opportunity Labels',
    filterName: 'Work Opportunity Labels is',
    type: 'autoComplete',
    ...options({
      sort: false,
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      isTag: true,
      children: 'name',
    }),
  },
  {
    name: 'requestedEmployee',
    label: 'Assigned Talent',
    filterName: 'Assigned Talent is',
    type: 'multiselect',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      onCell: true,
    }),
  },
  {
    name: 'requestedEmployeeId',
    label: 'Assigned Talent ID',
    filterName: 'Assigned Talent ID is',
    type: 'multiselect',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      onCell: true,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'requestedEmployeeGrade',
    label: 'Assigned Talent Grade',
    filterName: 'Assigned Talent Grade is',
    type: 'multiselect',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      onCell: true,
      display: false,
    }),
  },
  {
    name: 'requestedEmployeeFitScore',
    label: 'A.Fit',
    filterName: 'Assigned Talents Fit Score is',
    hideIfNoOpt: true,
    type: 'range',
    filtername: 'requestedEmployeeFit',
    ...options({
      customRender: true,
      customRenderValue: true,
      isShowColorBox: true,
      sort: false,
      filter: false,
      hasFilterOptions: true,
      formPath: {
        parent: 'mission',
        child: 'workOpportunities',
        childObject: 'detailedChange',
        childObj: 'requestedEmployeeFitScore',
        scoreKey: 'categoryScores',
        totalKey: 'totalScore',
      },
      hasInfo: true,
      hideColumnHeader: true,
      tooltipTitle:
        "Assigned Talents Fit Score only considers Booking Type 'C'",
      needSorting: false,
    }),
  },
  {
    name: 'requestedEmployeeOperatingUnit',
    label: 'Assigned Talent Operating Unit',
    filterName: 'Assigned Talent Operating Unit is',
    type: 'multiselect',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
    }),
  },
  {
    name: 'requestedEmployeeLabels',
    label: 'Assigned Talent Labels',
    filterName: 'Assigned Talent Labels is',
    type: 'autoComplete',
    ...options({
      sort: false,
      filter: true,
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      isTag: true,
    }),
  },
  {
    name: 'suggestedEmployee',
    label: 'Suggested Talent',
    filterName: 'Suggested Talent is',
    type: 'multiselect',
    ...options({
      customRender: true,
      // hasFilterOptions: true,
      customRenderValue: true,
      // onCell: true,
      // display: false,
    }),
  },
  {
    name: 'suggestedEmployeeId',
    label: 'Suggested Talent ID',
    filterName: 'Suggested Talent ID is',
    type: 'multiselect',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      onCell: true,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'suggestedEmployeeGrade',
    label: 'Suggested Talent Grade',
    filterName: 'Suggested Talent Grade is',
    type: 'multiselect',
    ...options({
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      isConvert: false,
      display: false,
    }),
  },
  {
    name: 'suggestedEmployeeFitScore',
    label: 'S.Fit',
    filterName: 'Suggested Talents Fit Score is',
    hideIfNoOpt: true,
    type: 'range',
    filtername: 'suggestedEmployeeFit',
    ...options({
      customRender: true,
      customRenderValue: true,
      isShowColorBox: true,
      sort: false,
      filter: false,
      hasFilterOptions: true,
      formPath: {
        parent: 'mission',
        child: 'workOpportunities',
        childObject: 'detailedChange',
        childObj: 'suggestedEmployeeFitScore',
        scoreKey: 'categoryScores',
        totalKey: 'totalScore',
      },
      hasInfo: true,
      hideColumnHeader: true,
      tooltipTitle:
        "Suggested Talents Fit Score only considers Booking Type 'C'",
      needSorting: false,
    }),
  },
  {
    name: 'suggestedEmployeeLabels',
    label: 'Suggested Talent Labels',
    filterName: 'Suggested Talent Labels is',
    type: 'autoComplete',
    ...options({
      sort: false,
      filter: true,
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      isTag: true,
      children: 'name',
    }),
  },
  {
    name: 'suggestedEmployeeOperatingUnit',
    label: 'Suggested Talent Operating Unit',
    filterName: 'Suggested Talent Operating Unit is',
    ...options({
      filterType: 'multiselect',
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
    }),
  },
  {
    name: 'financialYear',
    label: 'Financial Year',
    filterName: 'Financial Year is',
    type: 'multiselect',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
    }),
  },
  {
    name: 'financialYearEnd',
    label: 'Financial Year End',
    filterName: 'Financial Year End is',
    type: 'dateRange',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'coreTeam',
    label: 'Is Core Team',
    filterName: 'Is Core Team is',
    type: 'dropdown',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'changed',
    label: 'Is Changed',
    filterName: 'Is Changed = ',
    type: 'dropdown',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'isManuallyUpdated',
    label: 'Is Manually Updated',
    type: 'dropdown',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'isExcluded',
    label: 'Is Excluded',
    filterName: 'Is Excluded is',
    type: 'dropdown',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'isRequestedEmployeeGeneric',
    label: 'Current unassigned',
    filterName: 'Current unassigned = ',
    type: 'dropdown',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'isSuggestedEmployeeGeneric',
    label: 'Suggested unassigned',
    filterName: 'Suggested unassigned = ',
    type: 'dropdown',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'lastUpdatedAt',
    label: 'Updated at',
    filterName: 'Updated on ',
    type: 'dateRange',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'lastUpdatedBy',
    label: 'Updated by',
    filterName: 'Updated by ',
    type: 'multiselect',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      display: false,
      isName: true,
    }),
  },

  {
    ...actions,
  },
]

export const planningColumns = [
  {
    name: 'id',
    label: 'ID',
    category: 'details',
    detailGroup: 1,
    detailColIndex: 1,
    ...options({
      filter: false,
      display: false,
      customRender: true,
      isConvert: false,
      sortDirection: true,
    }),
  },
  {
    name: 'originalId',
    label: 'Original ID',
    category: 'details',
    detailGroup: 1,
    detailColIndex: 0,
    ...options({
      display: false,
      filter: true,
      customRender: true,
      customRenderValue: true,
      isConvert: false,
      isOriginalId: true,
    }),
  },
  {
    name: 'name',
    label: 'Name',
    category: 'details',
    detailGroup: 0,
    detailColIndex: 0,
    ...options({
      filter: true,
      customRender: true,
      customRenderValue: true,
      isConvert: false,
    }),
  },
  {
    name: 'postalCode',
    label: 'Postal Code',
    category: 'details',
    detailGroup: 1,
    detailColIndex: 3,
    ...options({
      filter: true,
      customRender: true,
      customRenderValue: true,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'city',
    label: 'City',
    category: 'details',
    detailGroup: 0,
    detailColIndex: 2,
    filterName: 'City is',
    ...options({
      filterType: 'multiselect',
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
    }),
  },
  {
    name: 'operatingUnit',
    label: 'Operating Unit',
    category: 'details',
    detailGroup: 0,
    detailColIndex: 3,
    filterName: 'Operating Unit is',
    type: 'multiselect',
    ...options({
      filterType: 'multiselect',
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
    }),
  },
  {
    name: 'startDate',
    label: 'Start Date',
    category: 'details',
    detailGroup: 2,
    type: 'dateRange',
    filterName: 'Start Date ',
    filterType: 'dateRange',
    ...options({
      hasFilterOptions: true,
      customRender: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'endDate',
    label: 'End Date',
    category: 'details',
    detailGroup: 2,
    type: 'dateRange',
    filterName: 'End Date ',
    ...options({
      hasFilterOptions: true,
      customRender: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'clientId',
    label: 'Client ID',
    category: 'details',
    detailGroup: 1,
    detailColIndex: 2,
    ...options({
      filter: true,
      display: false,
      customRender: true,
      customRenderValue: true,
      isConvert: false,
    }),
  },
  {
    name: 'clientName',
    label: 'Client Name',
    category: 'details',
    detailGroup: 0,
    detailColIndex: 1,
    type: 'multiselect',
    filterName: 'Client Name is',
    ...options({
      isConvert: false,
      customRender: true,
      hasFilterOptions: true,
      customRenderValue: true,
      filterType: 'multiselect',
    }),
  },
  {
    name: 'expectedEarnings',
    label: 'Expected Earnings',
    category: 'economics',
    filterName: 'Expected Earnings is',
    detailColIndex: 3,
    type: 'range',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
    }),
  },
  {
    name: 'expectedExpenses',
    label: 'Expected Expenses',
    category: 'economics',
    filterName: 'Expected Expenses is',
    type: 'range',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
    }),
  },
  {
    name: 'fee',
    label: 'Fee',
    category: 'economics',
    filterName: 'Fee is',
    type: 'range',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
    }),
  },
  {
    name: 'jobManager',
    label: 'Manager',
    category: 'details',
    detailGroup: 3,
    detailColIndex: 1,
    filterName: 'Manager is',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      filterType: 'multiselect',
      customRender: true,
    }),
  },
  {
    name: 'jobManagerId',
    label: 'Manager ID',
    category: 'details',
    detailGroup: 4,
    detailColIndex: 1,
    filterName: 'Manager ID is',
    ...options({
      hasFilterOptions: false,
      customRenderValue: true,
      customRender: true,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'jobPartner',
    label: 'Partner/Director',
    category: 'details',
    detailGroup: 3,
    detailColIndex: 0,
    filterName: 'Partner/Director is',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      filterType: 'multiselect',
      customRender: true,
    }),
  },
  {
    name: 'jobPartnerId',
    label: 'Partner/Director ID',
    category: 'details',
    detailGroup: 4,
    detailColIndex: 0,
    filterName: 'Partner/Director ID is',
    ...options({
      hasFilterOptions: false,
      customRenderValue: true,
      customRender: true,
      display: false,
      isConvert: false,
    }),
  },
  {
    name: 'totalHours',
    label: 'Total Hours',
    type: 'range',
    filterName: 'Total Hours ',
    ...options({
      customRender: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'assignedHours',
    label: 'Assigned Hours',
    type: 'range',
    filterName: 'Assigned Hours are',
    ...options({
      display: false,
      filter: true,
      customRender: true,
      customRenderValue: true,
      isConvert: false,
    }),
  },
  {
    name: 'unassignedHours',
    label: 'Unassigned Hours',
    type: 'range',
    filterName: 'Unassigned Hours are',
    ...options({
      customRender: true,
      customRenderValue: true,
    }),
  },
  {
    name: 'assignees',
    label: 'Assignee',
    filterName: 'Assignee is',
    ...options({
      display: false,
      filterType: 'multiselect',
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
    }),
  },
  {
    name: 'assigneesCount',
    label: 'Assignee Count',
    type: 'range',
    filterName: 'Assignee Count is',
    ...options({
      customRender: true,
      customRenderValue: true,
      display: false,
    }),
  },
  {
    name: 'financialYear',
    label: 'Financial Year',
    category: 'economics',
    filterName: 'Financial Year is',
    ...options({
      hasFilterOptions: true,
      customRenderValue: true,
      customRender: true,
      display: false,
    }),
  },
  {
    name: 'isForeignJob',
    label: 'Is Foreign Job ',
    category: 'details',
    detailGroup: 5,
    filterName: 'Is Foreign Job =',
    ...options({
      customRender: true,
      customRenderValue: true,
      display: false,
    }),
  },
  {
    name: 'lastUpdatedAt',
    label: 'Updated at',
    category: 'details',
    detailGroup: 5,
    filterName: 'Updated on ',
    type: 'dateRange',
    ...options({
      hasFilterOptions: true,
      customRender: true,
      customRenderValue: true,
      display: false,
    }),
  },
  {
    name: 'lastUpdatedBy',
    label: 'Updated by',
    category: 'details',
    detailGroup: 5,
    filterName: 'Updated by ',
    type: 'multiselect',
    ...options({
      hasFilterOptions: true,
      customRender: true,
      customRenderValue: true,
      display: false,
      isName: true,
    }),
  },
]

export const tabs = [
  { label: 'By Job', value: 'plannings' },
  { label: 'By Work Opportunity', value: 'bookings' },
]

export const planningsData = [
  {
    title: 'Operating Unit',
    key: 'operatingUnit',
    color: '#f56e00',
    hasInfo: false,
    description: 'Operating unit',
    needChart: true,
    lg: 4,
    loaderKey: 'operating_units',
  },
  {
    title: 'Assigned',
    key: 'distribution',
    color: '#f56e00',
    hasInfo: false,
    needChart: true,
    lg: 4,
    loaderKey: 'assigned',
    isFormatString: true,
  },
  {
    title: topLocation,
    key: 'city',
    color: '#f56e00',
    hasInfo: false,
    needChart: true,
    marginTop: 20,
    lg: 4,
    loaderKey: 'locations',
  },
]

const bookingData = [
  {
    title: 'Operating Unit',
    key: 'operatingUnit',
    color: '#f56e00',
    hasInfo: true,
    filterKey: 'operatingUnit',
    description: `Booking Operating Unit`,
    needChart: true,
    lg: 3,
    loaderKey: 'operating_units',
  },
  {
    title: 'Grade',
    key: 'grade',
    color: '#f56e00',
    hasInfo: true,
    filterKey: 'suggestedEmployeeGrade',
    description: `Suggested talent's grade`,
    needChart: true,
    lg: 3,
    loaderKey: 'requested_talent_grades',
  },
  {
    title: topLocation,
    key: 'location',
    color: '#f56e00',
    hasInfo: true,
    filterKey: 'jobCity',
    description: `Job Locations`,
    needChart: true,
    lg: 3,
    marginTop: 20,
    loaderKey: 'locations',
  },
  {
    title: 'Label Count',
    key: 'bookingLabels',
    needDropdown: true,
    needChart: false,
    group: true,
    groupingKey: 'label_category',
    lg: 3,
    selectArr: 'talent',
    formField: 'category',
    isClear: false,
    hasInfo: true,
    description: `Work Opportunity label count`,
    filterKey: 'bookingLabels',
    formPath: {
      parent: 'mission',
      child: 'bookingLabels',
    },
    formAttributes: {
      label: 'Category',
      isRequired: true,
      fullWidth: true,
      pattern: 'notEmptyOrNull',
      type: 'select',
      parentPath: 'mission',
      selectArray: 'workOpportunities',
      nestingChild: 'bookingLabels',
      mapKey: 'label_category',
      needUnique: true,
    },
    marginTop: 50,
    loaderKey: 'booking_labels',
  },
]

export const launchpadWOPath = {
  parent: launchpadFormPath,
  child: launchpadWorkOpportunitiesFormPath,
}

export const pieMockData = {
  plannings: planningsData,
  bookings: bookingData,
}

export const workOppTotalTitle = {
  plannings: {
    count: planningCounts,
    hours: planningCounts,
  },
  bookings: {
    count: 'Assigned Hours / Unassigned Hours',
    hours: 'Assigned Hours / Unassigned Hours',
  },
}

export const planningDetailsPath = {
  parent: missionFormPath,
  child: planningsDetails,
}

export const lpExpandRowDescription = {
  graphSelectorPath: {
    parent: missionFormPath,
    child: 'planningsDetails',
  },
  urlRedirection: workopportunity,
  rowChild: 'plannings',
  apiDetails: getBeforeBookings,
  graphOptions,
  flagPath: planningDetailsPath,
  rowSelectorPath: {
    ...missionWOPath,
  },
  pageEndPoint: apiEndPoints.plannings,
  graphPath: { parent: 'mission', child: 'planningsDetails' },
}

export const expandRowDescription = {
  graphSelectorPath: {
    parent: missionFormPath,
    child: 'planningsDetails',
  },
  urlRedirection: workopportunity,
  rowChild: 'plannings',
  apiDetails: getBeforeBookings,
  graphOptions,
  flagPath: planningDetailsPath,
  rowSelectorPath: {
    ...missionWOPath,
  },
  graphPath: { parent: 'mission', child: 'planningsDetails' },
  pageEndPoint: apiEndPoints.plannings,
  callAfter: true,
  periodPath: { ...missionWOPath, child: 'missionBooking' },
}

export const getCustomFilterNames = {
  plannings: [...woByJobGetFilterOptions],
  bookings: [
    {
      name: 'suggestedOperatingUnit',
      type: 'multiselect',
    },
    {
      name: 'jobCity',
      type: 'multiselect',
    },
    {
      name: 'engagement',
      type: 'multiselect',
    },
    {
      name: 'jobManager',
      type: 'multiselect',
    },
    {
      name: 'bookingGrade',
      type: 'multiselect',
    },
    {
      name: 'suggestedEmployeeGrade',
      type: 'multiselect',
    },
    {
      name: 'requestedEmployee',
      type: 'multiselect',
      bodyRenderLite: true,
      isColoredValue: true,
    },
    {
      name: 'suggestedEmployee',
      type: 'multiselect',
      bodyRenderLite: true,
      isColoredValue: true,
    },
    {
      name: 'clientName',
      type: 'multiselect',
    },
    {
      name: 'bookingType',
      type: 'multiselect',
    },
    {
      name: 'bookingStartDate',
      type: 'dateRange',
    },
    {
      name: 'bookingEndDate',
      type: 'dateRange',
    },
    {
      name: 'hours',
      type: 'range',
    },
    {
      name: 'bookingLabels',
      type: 'autoComplete',
    },
    {
      name: 'employeeLabels',
      type: 'autoComplete',
    },
    {
      name: 'suggestedEmployeeOperatingUnit',
      type: 'multiselect',
    },
    {
      name: 'bookingPriority',
      type: 'range',
    },
    {
      name: 'financialYearEnd',
      type: 'dateRange',
    },
    {
      name: 'jobFee',
      type: 'range',
    },
    {
      name: 'jobExpectedEarnings',
      type: 'range',
    },
    {
      name: 'jobExpectedExpenses',
      type: 'range',
    },
    {
      name: 'isExcluded',
      type: 'dropdown',
    },
    {
      name: 'lastUpdatedAt',
      type: 'dateRange',
    },
    {
      name: 'lastUpdatedBy',
      type: 'multiselect',
    },
    {
      name: 'coreTeam',
      type: 'dropdown',
    },
    {
      name: 'financialYear',
      type: 'multiselect',
    },
    {
      name: 'sdcRate',
      type: 'range',
    },
    {
      name: 'jobPostalCode',
      type: 'multiselect',
    },
    {
      name: 'jobManagerId',
      type: 'multiselect',
    },
    {
      name: 'jobPartnerId',
      type: 'multiselect',
    },
    {
      name: 'clientId',
      type: 'multiselect',
    },
    {
      name: 'requestedEmployeeId',
      type: 'multiselect',
    },
    {
      name: 'suggestedEmployeeId',
      type: 'multiselect',
    },
    {
      name: 'jobId',
      type: 'multiselect',
    },
    {
      name: 'suggestedEmployeeFitScore',
      type: 'range',
    },
    {
      name: 'requestedEmployeeFitScore',
      type: 'range',
    },
    {
      name: 'operatingUnit',
      type: 'multiselect',
    },
    {
      name: 'requestedEmployeeFit',
      type: 'range',
    },
    {
      name: 'suggestedEmployeeFit',
      type: 'range',
    },
    {
      name: 'suggestedEmployeeLabels',
      type: 'autoComplete',
    },
    {
      name: 'requestedEmployeeLabels',
      type: 'autoComplete',
    },
    {
      name: 'requestedEmployeeOperatingUnit',
      type: 'multiselect',
    },
    {
      name: 'requestedEmployeeGrade',
      type: 'multiselect',
    },
    {
      name: 'isSuggestedEmployeeGeneric',
      type: 'dropdown',
    },
    {
      name: 'isRequestedEmployeeGeneric',
      type: 'dropdown',
    },
    {
      name: 'changed',
      type: 'dropdown',
    },
    {
      name: 'isManuallyUpdated',
      type: 'dropdown',
    },
    {
      name: 'acceptanceState',
      type: 'multiselect',
    },
  ],
}

export const coreTeamTitle = 'Is Core Team'

const clientTitle = 'Clients'

export const totalTitle = {
  plannings: clientTitle,
  bookings: coreTeamTitle,
}

export const formAttributes = {
  row1: {
    rowFormMd: 6,
    formPath: {
      parent: missionFormPath,
      child: detailedChangePath,
    },
    formAttributes: {
      id: {
        label: '',
        type: 'hidden',
        isRequired: false,
        error: false,
        pattern: notEmptyOrNull,
        value: [],
      },
    },
  },
}

export const formAttributesOptimization = {
  row1: {
    ...formAttributes.row1,
    formAttributes: {
      name: {
        label: 'Search suggested talent',
        selectedLabel: 'Selected talent',
        type: 'autoComplete',
        isRequired: true,
        fullWidth: false,
        error: [],
        pattern: notEmptyOrNull,
        parentPath: missionFormPath,
        selectArray: suggestedEmployee,
        value: '',
        isMultiple: false,
      },
      ...formAttributes?.row1?.formAttributes,
    },
  },
}
export const formAttributesDataset = {
  row1: {
    ...formAttributes.row1,
    formAttributes: {
      name: {
        label: 'Search assigned talent',
        selectedLabel: 'Assigned talent',
        type: 'autoComplete',
        isRequired: true,
        fullWidth: false,
        error: [],
        pattern: notEmptyOrNull,
        parentPath: missionFormPath,
        selectArray: assignedEmployee,
        value: '',
        isMultiple: false,
      },
      ...formAttributes?.row1?.formAttributes,
    },
  },
}

export const customToolbarDescription = {
  markCoreTeam: {
    title: 'Update talent',
    key: 'id',
    openModal: true,
    permissionPath: {
      parent: 'work-opportunities',
      child: 'can_edit_assigned_talent',
    },
    ifOptimizationPermission: 'can_edit_suggested_talent',
  },
}

export const excludeKey = [
  'employeeLabels',
  'labels',
  'actions',
  'bookingLabels',
]

export const defaultColumnsFilter = [
  ...woByJobDefaultFilterOptions,
  'coreTeam',
  'isRequestedEmployeeGeneric',
  'isSuggestedEmployeeGeneric',
  'changed',
  'isManuallyUpdated',
  'financialYear',
  'isUnassigned',
]

export const disallowSuggestedTalent =
  'Accepted optimizations can not edit suggested talents'

export const tableTitle = {
  plannings: 'By Job',
  bookings: 'By Work Opportunity',
}

export const getCustomNames = [
  {
    name: 'grade',
    type: 'multiselect',
  },
  {
    name: 'hours',
    type: 'range',
  },
  {
    name: 'fit',
    type: 'range',
  },
  {
    name: 'time',
    type: 'range',
  },
  {
    name: 'timeFte',
    type: 'range',
  },
  {
    name: 'entryDate',
    type: 'dateRange',
  },
  {
    name: 'leaveDate',
    type: 'dateRange',
  },
  {
    name: 'labels',
    type: 'autoComplete',
  },
  {
    name: 'newJoiner',
    type: 'dropdown',
  },
  {
    name: 'modifiedLabel',
    type: 'autoComplete',
  },
]

export const downloadLoaderPath = {
  parent: missionFormPath,
  child: detailedChangePath,
}
export const detailChangePath = {
  ...missionWOPath,
  childObject: detailedChangePath,
}

export const removeFilter = [
  'changed',
  'isRequestedEmployeeGeneric',
  'isSuggestedEmployeeGeneric',
  'isManuallyUpdated',
]

export const planningsPath = {
  ...missionWOPath,
  childObject: 'plannings',
}

export const workOpportunityFormPath = {
  parent: missionFormPath,
  child: launchpadWorkOpportunitiesFormPath,
  childObject: detailedChangePath,
}

export const selectApiPath = {
  plannings: planningsPath,
  bookings: workOpportunityFormPath,
}

export const bookingChildObject = {
  plannings,
  bookings: 'detailedChange',
}
export const selectBodyMessage = {
  plannings: noPlanningFound,
  bookings: noDetailChangesFound,
}
export const formPath = {
  parent: missionFormPath,
  child: missionDetails,
}

export const modalPath = {
  parent: missionFormPath,
  child: detailedChangePath,
  childObject: 'isModal',
}

export const suggestedTalentLocalKey = 'suggested-talent'

export const assignedTalentLocalKey = 'assigned-talent'

/**
 * Inherit from the launchpad-WO
 */

export const lpFormPath = {
  parent: launchpadFormPath,
  child: launchpadWorkOpportunitiesFormPath,
  childObject: 'plannings',
}

export const launchpadFilterChipPath = {
  parent: 'launchpad',
  child: 'filterChip',
  childObject: 'bookings',
}

export const labelModalPath = {
  parent: 'mission',
  child: 'workOpportunity-label-modal',
  childObject: launchpadLabels,
}

export const labelFormAttr = {
  default1: {
    formPath: {
      parent: 'mission',
      child: 'workOpportunity-label-modal',
    },
    formAttributes: {
      labels: {
        label: 'Labels *',
        type: 'autoComplete',
        isRequired: true,
        fullWidth,
        isLabelPermission: true,
        permissionsParent: 'work-opportunities',
        error: 'false',
        pattern: defaultTrueValidation,
        parentPath: 'launchpad',
        selectArray: 'labels',
        placeholder: 'Labels',
        limitTags: 3,
        needClass: true,
        arrangeByGroup: true,
        groupBy: 'labelCategory',
        sortValues: true,
        sortKey: 'labelCategory',
        autoFocus: true,
        renderTag: true,
        modalNote: {
          add: addLabelNote,
          remove: removeLabelNote,
        },
      },
    },
  },
}

export const labelForm = [
  {
    type: null,
    fields: [labelFormAttr],
  },
]

export const labelUpdatePayloadKey = 'bookingIds'

export const updateFormPath = {
  parent: launchpadFormPath,
  child: launchpadWorkOpportunitiesFormPath,
  childObject: workOpportunities,
}

const updateCard = {
  updateCardData: true,
  cardApiDetails: cardDetail,
  cardDataPath: downloadLoaderPath,
  endPoint: apiEndPoints.datasets,
  active: workOpportunities,
  needQuery: true,
  needSearch: true,
  permissionPath: { parent: 'work-opportunities', child: 'can_edit' },
}

const datasetModalPath = {
  parent: 'mission',
  child: 'detailedChange',
}

const datasetUpdateTalentAction = {
  updateLabel: {
    title: 'Update talent',
    key: 'id',
    openModal: true,
    datasetModalPath: { ...datasetModalPath },
    permissionPath: {
      parent: 'work-opportunities',
      child: 'can_edit_assigned_talent',
    },
    ifOptimizationPermission: 'can_edit_suggested_talent',
  },
}

export const datasetCustomToolbarDescription = {
  markCoreTeam: {
    title: 'Add to Core Team',
    key: 'coreTeam',
    action: 'add',
    addName: 'addIds',
    apiObject: launchpadWorkOpportunitiesFormPath,
    ...updateCard,
  },
  unMarkCoreTeam: {
    title: 'Remove from Core Team',
    key: 'coreTeam',
    action: 'remove',
    removeName: 'removeIds',
    apiObject: launchpadWorkOpportunitiesFormPath,
    ...updateCard,
  },
  addLabel: {
    title: 'Add label',
    key: launchpadWorkOpportunitiesFormPath,
    action: 'add',
    payloadKey: 'booking_ids',
    apiObject: 'labels',
    openModal: true,
    permissionPath: {
      parent: 'work-opportunities',
      child: 'can_edit_label_assignment',
    },
    hasIntegrationPermissions: true,
    alternativeEnable: 'can_edit_non_csv_integration_label_assignment',
  },
  removeLabel: {
    title: 'Remove label',
    key: launchpadWorkOpportunitiesFormPath,
    action: 'remove',
    payloadKey: 'booking_ids',
    apiObject: 'labels',
    openModal: true,
    permissionPath: {
      parent: 'work-opportunities',
      child: 'can_edit_label_assignment',
    },
    hasIntegrationPermissions: true,
    alternativeEnable: 'can_edit_non_csv_integration_label_assignment',
  },
  ...datasetUpdateTalentAction,
}

export const lpPlanningsData = [
  {
    title: 'Operating Unit',
    key: 'operatingUnit',
    color: '#f56e00',
    hasInfo: false,
    description: 'Operating unit',
    needChart: true,
    lg: 4,
    loaderKey: 'operating_units',
  },
  {
    title: 'Assigned',
    key: 'distribution',
    color: '#f56e00',
    hasInfo: false,
    needChart: true,
    lg: 4,
    loaderKey: 'assignedHours',
    isFormatString: true,
  },
  {
    title: topLocation,
    key: 'city',
    color: '#f56e00',
    hasInfo: false,
    needChart: true,
    lg: 4,
    loaderKey: 'locations',
  },
]
const lpBookingData = [
  {
    title: 'Operating Unit',
    key: 'operatingUnit',
    color: '#f56e00',
    hasInfo: false,
    description: `Suggested talent's operating unit`,
    needChart: true,
    lg: 3,
    loaderKey: 'operating_units',
  },
  {
    title: 'Requested Grade',
    key: 'grade',
    filterKey: 'bookingGrade',
    color: '#f56e00',
    hasInfo: false,
    description: `Suggested talent's Grade`,
    needChart: true,
    lg: 3,
    loaderKey: 'bookingGrade',
  },
  {
    title: topLocation,
    key: 'location',
    filterKey: 'jobCity',
    color: '#f56e00',
    hasInfo: false,
    description: `Talent's top six cities`,
    needChart: true,
    lg: 3,
    loaderKey: 'locations',
  },
  {
    title: 'Label Count',
    key: 'labels',
    needDropdown: true,
    needChart: false,
    group: true,
    groupingKey: 'label_category',
    lg: 3,
    selectArr: 'talent',
    formField: 'category',
    isClear: false,
    formPath: {
      parent: 'mission',
      child: 'bookingLabel',
    },
    filterKey: 'bookingLabels',
    formAttributes: {
      label: 'Category',
      isRequired: true,
      fullWidth: true,
      pattern: 'notEmptyOrNull',
      type: 'select',
      parentPath: 'mission',
      selectArray: 'workOpportunities',
      nestingChild: 'labels',
      mapKey: 'label_category',
      needUnique: true,
    },
    loaderKey: 'labels',
  },
]

export const lpPieMockData = {
  plannings: lpPlanningsData,
  bookings: lpBookingData,
}

export const lpDefaultColumnsFilter = [
  ...woByJobDefaultFilterOptions,
  'coreTeam',
  'financialYear',
  'isUnassigned',
]

export const CloseSuggestedTalent = 'Close Suggested Talent'

export const CloseAssignedTalent = 'Close Assigned Talent'
