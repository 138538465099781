/* eslint-disable no-unused-vars */
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import AccountIcon from '@material-ui/icons/Person'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { headerProfileContainer } from '../../../container/layout/headerProfile.container'
import {
  contactSupport,
  signOut,
  structure,
} from '../../../description/header.description'
import SettingIcon from '../../../shared/icons/SettingIcon'
import SignOutIcon from '../../../shared/icons/SignOutIcon'
import SupportIcon from '../../../shared/icons/SupportIcon'
import RPCLink from '../../../shared/RPCLink'
import RPCMenu from '../../../shared/RPCMenu'
import RPCTypography from '../../../shared/RPCTypography'
import { NOTIFICATIONS, uid } from '../../../utils/constant'
import { ternary } from '../../../utils/javascript'

const HeaderProfile = memo(({ classes, hasGroup }) => {
  const rolePermissions = useSelector((state) => state.app?.permissions)
  const accessKeys = Object.keys(rolePermissions)
  const { isOpen, toggle, user, group, handleLogoutSession, responsiveToggle } =
    headerProfileContainer()
  return (
    <>
      {ternary(
        hasGroup,
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => toggle(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>,
        <IconButton
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={() => handleLogoutSession()}
        >
          <ExitToAppIcon classes={{ root: classes.headerIcon }} />
        </IconButton>,
      )}
      <RPCMenu
        id="profile-menu"
        open={Boolean(isOpen)}
        anchorEl={isOpen}
        onClose={() => toggle(null)}
        className={classes.headerMenu}
        classes={{ paper: classes.profileMenu }}
        disableAutoFocusItem
      >
        {user && (
          <div className={classes.profileMenuUser}>
            <RPCTypography variant="h4" weight="medium">
              {user.firstName} {user.lastName}
            </RPCTypography>
            <RPCTypography weight="small">{user.email}</RPCTypography>
            <RPCTypography weight="small">{group.fullName}</RPCTypography>
          </div>
        )}
        {structure.map(
          ({ link, icon, label, openInNewTab, pagePermission }) =>
            accessKeys.includes(pagePermission) && (
              <React.Fragment key={uid()}>
                <ListItem
                  button
                  component={link ? Link : undefined}
                  className={classes.profileMenuLink}
                  onClick={() => responsiveToggle()}
                  to={link || ''}
                  disableRipple
                  target={openInNewTab ? '_blank' : undefined}
                >
                  <ListItemIcon className={classes.profileMenuIcon}>
                    {icon}
                  </ListItemIcon>
                  <ListItemText primary={label} />
                </ListItem>
              </React.Fragment>
            ),
        )}
        <ListItem
          button
          component={Link}
          className={classes.profileMenuLink}
          onClick={() => responsiveToggle()}
          to={'/notifications' || ''}
          disableRipple
          target={undefined}
        >
          <ListItemIcon className={classes.profileMenuIcon}>
            <SettingIcon />
          </ListItemIcon>
          <ListItemText primary={NOTIFICATIONS} />
        </ListItem>
        <RPCLink
          href={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}
          className={classes.contactLink}
          isBlank
        >
          <ListItem button className={classes.profileMenuLink} disableRipple>
            <ListItemIcon className={classes.profileMenuIcon}>
              <SupportIcon />
            </ListItemIcon>
            <ListItemText primary={contactSupport} />
          </ListItem>
        </RPCLink>
        <ListItem
          button
          className={classes.profileMenuLink}
          onClick={() => handleLogoutSession()}
          disableRipple
        >
          <ListItemIcon className={classes.profileMenuIcon}>
            <SignOutIcon />
          </ListItemIcon>
          <ListItemText primary={signOut} />
        </ListItem>
      </RPCMenu>
    </>
  )
})

export default HeaderProfile
