import { equal } from '../../utils/javascript'
import { reloadFn } from '../../utils/window'
import {
  ADD_PIWIK_INSTANCE,
  APP_AUTH,
  CHECK_MATOMO_STATUS,
  CHECK_SENTRY_STATUS,
  CLEAR_SESSION,
  MANAGE_SIDE_BAR_MENU,
  SET_FIREBASE_CONFIG,
  SIDEBAR_OPENED,
} from '../constants'
import {
  firebaseConfig,
  login,
  logout,
  motomo,
  refreshToken,
  sentry,
  session,
} from '../description'
import { apiAction, changeLoadingStatus } from './api.action'

export const loginAction =
  ({ formPath }) =>
  async (dispatch) => {
    const { status } = await dispatch(
      apiAction({
        apiDetails: login,
        formPath,
        loaderFormPath: formPath,
      }),
    )
    if (status) {
      dispatch(sessionAction())
    }
    dispatch(changeLoadingStatus(formPath, false))
  }
export const getMatomo = () => async (dispatch) => {
  const { status, data } = await dispatch(
    apiAction({
      apiDetails: motomo,
    }),
  )
  if (status) {
    dispatch({ type: CHECK_MATOMO_STATUS, payload: data })
  }
}
export const getSentry = () => async (dispatch) => {
  const { status, data } = await dispatch(
    apiAction({
      apiDetails: sentry,
    }),
  )
  if (status) {
    dispatch({ type: CHECK_SENTRY_STATUS, payload: data })
  }
}
export const setPiwikInstance = (data) => async (dispatch) => {
  if (data) {
    await dispatch({ type: ADD_PIWIK_INSTANCE, payload: data })
  }
}

export const sessionAction = () => async (dispatch) => {
  const { status, data, errorCode } = await dispatch(
    apiAction({ apiDetails: session }),
  )
  const apiData = { ...data }
  if (!status) {
    if (equal(errorCode, '4000')) {
      apiData.isAuth = true
      apiData.hasGroup = false
    } else {
      await dispatch({ type: CLEAR_SESSION })
    }
  } else {
    apiData.isAuth = true
    apiData.hasGroup = true
  }
  apiData.isSessionChecked = true
  dispatch(authTokenAction(apiData))
  dispatch(getSentry())
  setInterval(() => {
    dispatch(refreshTokenAction())
  }, 1000 * 60)
}

export const sidebarOpenedAction = () => async (dispatch) => {
  await dispatch({ type: SIDEBAR_OPENED })
}

export const refreshTokenAction = () => async (dispatch) => {
  const { status } = await dispatch(apiAction({ apiDetails: refreshToken }))
  if (!status) {
    await dispatch({ type: CLEAR_SESSION })
  } else {
    await dispatch(getMatomo())
  }
}

export const manageSideBarMenuAction = (params) => async (dispatch) => {
  await dispatch({ type: MANAGE_SIDE_BAR_MENU, payload: params })
}

export const authTokenAction = (data) => async (dispatch) => {
  dispatch({
    type: APP_AUTH,
    payload: data,
  })
}

export const clearSessionAction = () => async (dispatch) => {
  const { status } = await dispatch(
    apiAction({
      apiDetails: logout,
    }),
  )
  await dispatch(getMatomo())

  if (status) {
    reloadFn()
  }
}

export const getFirebaseConfig = () => async (dispatch) => {
  const { status, data } = await dispatch(
    apiAction({
      apiDetails: firebaseConfig,
    }),
  )
  if (status) {
    dispatch({ type: SET_FIREBASE_CONFIG, payload: data })
  }
}
